import { Container, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { graphql } from "gatsby"
import i18next from "i18next"
import React from "react"
import AdAppCounting from "../components/AdAppCounting"
import CalorieChart from "../components/CalorieChart"
import ClusterCategoryTeaser from "../components/ClusterCategoryTeaser"
import CommunitySuccesses from "../components/CommunitySuccesses"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import withTrans from "../components/withTrans"
import theme from "../theme"

const SecondPage = ({ data, pageContext, t }) => {
  const foods = data.allFoodCategory.nodes[0].childrenFood
  const categoryKey = data.allFoodCategory.nodes[0].key
  const categoryName = data.allFoodCategory.nodes[0].name
  const similiarCategories =
    data.allFoodCategory.nodes[0].parent.childrenFoodCategory.filter(
      c => c.slug !== pageContext.slug
    )

  return (
    <Layout pageName="calories" theme={theme}>
      <Container>
        <PageHeading
          title={t("category_page.heading_1", { category: categoryName })}
        >
          <Box mb={6}>{t(`category_page.teaser.text.cat_${categoryKey}`)}</Box>
        </PageHeading>
        <AdAppCounting />

        <br />

        <CalorieChart foods={foods} categoryKey={categoryKey} />

        <br />
        <br />

        <ClusterCategoryTeaser
          title={t("category_page.recommendations.heading")}
          categories={similiarCategories}
        />

        <br />
        <br />

        <Box>
          <Typography component="div" variant="h3">
            {t("banner.success_story.title")}
          </Typography>
        </Box>

        <CommunitySuccesses />
      </Container>
    </Layout>
  )
}

export default withTrans(SecondPage)

export const query = graphql`
  query Foods($slug: String) {
    allFoodCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        key
        name
        parent {
          ... on ClusterCategory {
            childrenFoodCategory {
              key
              name
              slug
            }
          }
        }
        childrenFood {
          name
          unitCalories100gml
          calories100Gml
          serving1MlG
          serving1Size
          serving1Unit
          serving1UnitNumber
          serving2MlG
          serving2Size
          serving2Unit
          serving2UnitNumber
          serving3MlG
          serving3Size
          serving3Unit
          serving3UnitNumber
          serving4MlG
          serving4Size
          serving4Unit
          serving4UnitNumber
          serving5MlG
          serving5Size
          serving5Unit
          serving5UnitNumber
          displayPortionCalories
          displayServingMlG
          displayServingSize
          displayServingUnit
          displayServingUnitNumber
          displayServingUnitOption
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  const categoryName = data.allFoodCategory.nodes[0].name
  return (
    <Seo title={i18next.t("category_page.title", { category: categoryName })} />
  )
}
