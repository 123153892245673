import styled from "@emotion/styled"
import { InputAdornment, OutlinedInput, useMediaQuery } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import React from "react"
import theme from "../theme"
import KnetIcon from "./KnetIcon"
import withTrans from "./withTrans"

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  borderRadius: "8px",
  fontWeight: "400",
  fontSize: "16px",
  width: "100%",
  lineHeight: "24px",
  "& .MuiOutlinedInput-input": {
    padding: theme.spacing(1.5, 1, 1.5, 1),
    // vertical padding + font size from searchIcon
    width: "100%",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
      "&:focus": {},
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
  },
}))

const SearchTextField = ({
  searchQuery,
  setSearchQuery,
  forTable = false,
  t,
}) => {
  const upMd = useMediaQuery(theme.breakpoints.up("md"))
  const handleOnInput = event => {
    setSearchQuery(event.target.value)
  }

  const handleKeyboardEvent = event => {
    if (event.keyCode === 13) {
      event.target.blur()
    }
  }

  return (
    <StyledInputBase
      placeholder={t(
        `${true ? "search.table.placeholder" : "search.general.placeholder"}`
      )}
      fullWidth={upMd ? false : true}
      inputProps={{ "aria-label": "search", className: "calorie-chart-search" }}
      value={searchQuery}
      id="search-input"
      name="s"
      onInput={handleOnInput}
      onKeyUp={handleKeyboardEvent}
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            size="small"
            aria-label="toggle password visibility"
            edge="start"
          >
            <KnetIcon icon="search" color={theme.palette.text.light} />
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default withTrans(SearchTextField)
