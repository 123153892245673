import styled from "@emotion/styled"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
  Button,
  Grid,
  IconButton,
  Tab,
  TableHead,
  TableSortLabel,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import i18next, { t } from "i18next"
import PropTypes from "prop-types"
import * as React from "react"
import img_icon_kcal from "../assets/images/icon_kcal.svg"
import locales from "../locales"
import theme from "../theme"
import SearchTextField from "./SearchTextField"
import Textblock from "./Textblock"
import TextTeaser from "./TextTeaser"
import withTrans from "./withTrans"
import Link from "gatsby-link";
import CampaignLinkDecorator from "./CampaignLinkDecorator";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  "& .MuiTableSortLabel-icon": {
    color: "#667085 !important",
  },
}))

const StyledToggleButton = styled(ToggleButton)(() => ({
  border: "none !important",
  borderRadius: "8px !important",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "bold",
  padding: "15px",
  color: "#344054",
  backgroundColor: "#F2F4F7",
  "&:not(:last-of-type)": {
    marginRight: "10px",
    borderRadius: "8px !important",
  },
  "&.Mui-selected": {
    color: "#087443",
  },
}))

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

function descendingComparator(a, b, orderBy) {
  let bb = b[orderBy]
  let aa = a[orderBy]

  if (isNumeric(a[orderBy]) && isNumeric(b[orderBy])) {
    bb = parseInt(b[orderBy])
    aa = parseInt(a[orderBy])
  }

  if (bb < aa) {
    return -1
  }
  if (bb > aa) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const EnhancedTableHead = props => {
  const { order, orderBy, perUnit, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  const dp = { xs: "none" }

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("category_page.table.heading.food"),
      showFrom: "xs",
      showOnTab: ["100g", "portion"],
      align: "left",
      width: "70%",
    },
    {
      id: "100g",
      numeric: false,
      disablePadding: false,
      label: t("category_page.table.heading.serving"),
      showFrom: "md",
      showOnTab: ["100g"],
      align: "left",
      width: "20%",
    },
    {
      id: "calories100Gml",
      numeric: false,
      disablePadding: false,
      label: t("category_page.table.heading.calories"),
      showFrom: "xs",
      showOnTab: ["100g"],
      width: "20%",
    },
    {
      id: "serving1Size",
      numeric: false,
      disablePadding: false,
      label: t("category_page.table.heading.serving"),
      showFrom: "md",
      showOnTab: ["portion"],
      align: "left",
      width: "20%",
    },
    {
      id: "displayPortionCalories",
      numeric: false,
      disablePadding: false,
      label: t("category_page.table.heading.calories"),
      showFrom: "xs",
      showOnTab: ["portion"],
      width: "20%",
    },
  ]

  return (
    <TableHead sx={{ backgroundColor: "#F9FAFB" }}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{
              display: {
                ...dp,
                [headCell.showFrom]: `${
                  headCell.showOnTab.includes(perUnit) ? "table-cell" : "none"
                }`,
              },
              width: `${headCell.width}`,
              textAlign: "left",
              padding: "12px",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "100g" ? (
              <StyledTableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </StyledTableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = props => {
  const { perUnit, handlePerUnitChange, searchQuery, setSearchQuery } = props

  return (
    <Toolbar disableGutters>
      <Grid container row rowSpacing={{ xs: 2 }} justifyContent="space-between">
        <Grid item xs={12} md={8} textAlign="left">
          <ToggleButtonGroup
            color="secondary"
            value={perUnit}
            exclusive
            onChange={e => {
              handlePerUnitChange(e.target.value)
            }}
          >
            <StyledToggleButton
              className="chart-view-switcher"
              selected={perUnit === "100g"}
              value="100g"
              id="ShowPer100"
            >
              {t("category_page.switcher.show_per_100g")}
            </StyledToggleButton>
            <StyledToggleButton
              selected={perUnit === "portion"}
              className="chart-view-switcher"
              value="portion"
              id="ShowPerServing"
            >
              {t("category_page.switcher.show_per_serving")}
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <SearchTextField
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

const CalorieChart = ({ foods, categoryKey, t }) => {
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("name")
  const [perUnit, setPerUnit] = React.useState("100g")
  const [value, setValue] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState("")
  const downMd = useMediaQuery(theme.breakpoints.down("md"))
  const pages = locales[i18next.language].pages

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handlePerUnitChange = value => {
    setPerUnit(value)
    setOrderBy("asc")
    setOrderBy("name")
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const displayFoodName = name => {
    const i = name.indexOf("|")
    if (i > -1) {
      const splits = [name.slice(0, i), name.slice(i + 1)]
      return splits[0]
    } else {
      return name
    }
  }

  const unitString = data => {
    return perUnit === "100g"
      ? `100 ${getServingString(data)}`
      : `${getPortionString(data)}`
  }

  const getPortionString = data => {
    const servingNumber = data[`displayServingUnitNumber`] || data[`serving1UnitNumber`] || "";
    const servingUnit = data[`displayServingUnit`] ? t(data[`displayServingUnit`]) : t(data[`serving1Unit`]) || "";
    const servingOption = data[`displayServingUnitOption`] ? ", " + t(data[`displayServingUnitOption`]) : "";
    const servingSize = data[`displayServingSize`] || data[`serving1Size`] || "0";
    const servingMlG = data[`displayServingMlG`] || "g";

    return `${servingNumber} ${servingUnit}${servingOption} (${servingSize} ${servingMlG})`;
  }

  const getServingString = data => {
    return `${data[`displayServingMlG`] ? t(data[`displayServingMlG`]) : "g"}`
  }

  const filteredFoods = foods.filter(f => {
    if (!searchQuery) {
      return true
    }
    return f.name.toLowerCase().includes(searchQuery.toLowerCase())
  })

  React.useEffect(() => {
    let locale = locales[i18next.language].locale;
    if (locale === "EN" || locale === "PT") {
      setPerUnit("portion");
    } else {
      setPerUnit("100g");
    }
  }, []);

  const table = (
    <React.Fragment>
      <EnhancedTableToolbar
        perUnit={perUnit}
        handlePerUnitChange={handlePerUnitChange}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <TableContainer
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: "8px",
          marginTop: "20px",
        }}
      >
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            perUnit={perUnit}
            onRequestSort={handleRequestSort}
            rowCount={foods.length}
          />

          <TableBody>
            {stableSort(filteredFoods, getComparator(order, orderBy)).map(
                (row, index) => {
                  if (index === 10 || (filteredFoods.length < 10 && index === filteredFoods.length - 1)) {
                    // Render your special table row for every 10th iteration
                    return (
                        <TableRow key={row.name}>
                          <TableCell colSpan={3} align="center">
                            <div>
                              <h3>{t("banner.intable1.headline")}</h3>
                              <p>{t("banner.intable1.subheadline")}</p>
                              <Button
                                  color="secondary"
                                  variant="contained"
                                  disableElevation
                                  component={Link}
                                  to={CampaignLinkDecorator({ language: i18next.language, utm_campaign: 'intable_banner1' })}
                                  sx={{
                                    width: { md: 343 },
                                    backgroundColor: "#099250",
                                    color: theme.palette.text.light,
                                  }}
                              >
                                {t("banner.intable1.button")}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                    );
                  }

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    <TableCell
                      component="td"
                      scope="row"
                      padding="2"
                      sx={{
                        display: {
                          xs: `table-cell`,
                        },
                      }}
                    >
                      <a
                        style={{ textDecoration: "none" }}
                        target="_new"
                        href={`https://www.yazio.com/${
                          i18next.language
                        }/search?q=${displayFoodName(row.name)}`}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            textDecoration: "underline",
                            display: "inline-block",
                            textAlign: "left",
                          }}
                          variant="body2"
                        >
                          {displayFoodName(row.name)}
                        </Typography>
                      </a>

                      {downMd && (
                        <Typography
                          variant="body2"
                          sx={{
                            display: { xs: "block", md: "none" },
                            textAlign: "left",
                          }}
                        >
                          {unitString(row)}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        display: {
                          xs: "none",
                          md: perUnit === "100g" ? `table-cell` : `none`,
                        },
                        textAlign: "left",
                      }}
                      align="right"
                    >
                      <Typography variant="body2">{`100 ${getServingString(
                        row
                      )}`}</Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        display: {
                          xs: perUnit === "100g" ? `table-cell` : `none`,
                        },
                        textAlign: "left",
                      }}
                      align="right"
                    >
                      {`${Math.round(row.calories100Gml)} ${t(
                        "category_page.table.content.calories"
                      )}`}
                    </TableCell>

                    <TableCell
                      sx={{
                        display: {
                          xs: "none",
                          md: perUnit === "portion" ? `table-cell` : `none`,
                        },
                        textAlign: "left",
                      }}
                      align="right"
                    >
                      <Typography variant="body2">
                        {getPortionString(row)}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        display: {
                          xs: perUnit === "portion" ? `table-cell` : `none`,
                        },
                        textAlign: "left",
                      }}
                      align="right"
                    >
                      {`${Math.round(row.displayPortionCalories || row.calories100Gml)} ${t(
                        "category_page.table.content.calories"
                      )}`}
                    </TableCell>
                  </TableRow>
                )
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )

  return (
    <Paper elevation={0} sx={{ p: 0, my: 2 }}>
      { (t(`category_page.bottom.text.cat_${categoryKey}`) !== `category_page.bottom.text.cat_${categoryKey}`) &&
      <Box component="div" sx={{ pt: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
          variant="fullWidth"
          sx={{ display: { xs: "block", sm: "none" }, paddingTop: 0 }}
        >
          <Tab
            label={t("category_page.tabs.table.heading")}
            className="chart-text-switcher"
            id="Charts"
          />
          <Tab
            label={t("category_page.tabs.text.heading")}
            className="chart-text-switcher"
            id="Information"
          />
        </Tabs>
      </Box>
      }
      <TabPanel value={value} index={0}>
        {table}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Textblock className={"bottom-category-text"}>
          <div
            dangerouslySetInnerHTML={{
              __html: t(`category_page.bottom.text.cat_${categoryKey}`),
            }}
          />
        </Textblock>
      </TabPanel>

      <br />
      <br />
      <TextTeaser
          icon={<img src={img_icon_kcal} height={36} width={36} alt="" />}
          variant="filled"
          severity="success"
          to={pages.find(p => p.key === "calorie-intake-calculator").path}
          action={
            <IconButton
                to={pages.find(p => p.key === "calorie-intake-calculator").path}
            >
              <ArrowForwardIosIcon sx={{ color: "#fff" }} />
            </IconButton>
          }
      >
        <Typography component="span" variant="body2" sx={{ fontSize: 16 }}>
          {t("banner.calorie_intake.text_old")}
        </Typography>
      </TextTeaser>
      { (t(`category_page.bottom.text.cat_${categoryKey}`) !== `category_page.bottom.text.cat_${categoryKey}`) &&
        <Textblock
          sx={{ display: { xs: "none", sm: "block" } }}
          className={"bottom-category-text"}
        >
          <br />
          <br />

          <div
            dangerouslySetInnerHTML={{
              __html: t(`category_page.bottom.text.cat_${categoryKey}`),
            }}
          />
        </Textblock>
      }
    </Paper>
  )
}

export default withTrans(CalorieChart)
