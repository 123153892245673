import { Box } from "@mui/system"
import { Link } from "gatsby-theme-material-ui"
import * as React from "react"

const TextTeaser = ({ icon, action, to, children }) => {
  return (
      <Box
          component={"div"}
          className="quick-action-calorie-intake"
          id="quick-action-layout-2"
      >
        <Link to={to} sx={{ textDecoration: "none" }}>
          <Box
              className="quick-action-calorie-intake"
              id="quick-action-layout-2"
              sx={{
                backgroundColor: "#099250",
                borderRadius: "12px",
                color: "#fff",
                padding: "20px 20px",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                alignItems: "center",
              }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {icon} <span style={{ marginLeft: "10px" }}>{children}</span>
            </div>
            {action}
          </Box>
        </Link>
      </Box>
  )
}

export default TextTeaser
